
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



























































































































.bold {
  font-weight: 600;
}

h1.h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 100% */
  text-transform: uppercase;
}

h2.titleForm {
  font-weight: 200;
  width: 100%;
  color: $c-main-primary;
  padding-bottom: 1.5rem;
}

h3.titleForm {
  font-weight: 600;
  font-size: 2rem;
  color: $c-black;
  width: 100%;
  padding: 3rem 0 3rem 0;
}

.action_add {
  padding-top: 5em;
}

button {
  border: none;
  border-radius: 50px;
  background-color: $c-orange;
  color: $c-white;
  outline: 0;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px 8px 12px;
}
