
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
































































































.ean__item__pin {
  // prettier-ignore
  @include fluid(width, (xxs: 36px, xxl: 50px));
  // prettier-ignore
  @include fluid(height, (xxs: 36px, xxl: 50px));
  // prettier-ignore
  @include fluid(margin-right, (xxs: 10px, xxl: 10px));

  flex-shrink: 0;
  fill: $c-orange;
}

.header__icon {
  // prettier-ignore
  @include fluid(width, (xxs: 36px, xxl: 50px));
  // prettier-ignore
  @include fluid(height, (xxs: 36px, xxl: 50px));
  // prettier-ignore
  @include fluid(margin-right, (xxs: 10px, xxl: 10px));

  &.elec {
    fill: $c-main-secondary;
  }

  &.gaz {
    fill: $c-orange;
  }

  &.propane {
    fill: $c-blue;
  }
  /*
  @include mq(s) {
    width: 4.8rem;
    height: 4.8rem;
    margin: 0 0.5rem 0 -1.5rem;
  }

 */
}

.info_head_terminal {
  color: $c-black;

  .head_info {
    width: 50%;
    display: flex;

    @media screen and (max-width: 560px) {
      width: 100%;
      padding-bottom: 5px;
    }
  }

  .ean__item__address {
    width: 100%;
  }

  .small {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .text {
    font-size: 1.5rem;
  }

  .lead {
    font-size: 2rem;
    font-weight: 400;
  }

  @media screen and (max-width: 560px) {
    .head_info {
      width: 100%;
    }

    .info_ean {
      padding-top: 10px;
      padding-left: 5px;
    }
  }
}
