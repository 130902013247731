
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


































































































































































































.section {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  color: #333;

  h4 {
    padding-top: 3rem;
  }
}
