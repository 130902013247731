/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * for the naming use http://chir.ag/projects/name-that-color/
 * from darker to lighter ?
 */

// Global colors
$c-black: rgb(0, 0, 0);
$c-gray-darkest: #2d2d2d;
$c-gray-darker: #575756;
$c-gray-dark: #858585;
$c-gray-medium: rgb(111, 113, 117);
$c-gray: #b2b2b2;
$c-gray-light: #d8d8d8;
$c-gray-lighter: #e6e6e6;
$c-gray-lightest: #f5f5f5;
$c-gray-info: #c0c0c0;
$c-gray-separator: #bababa;
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;
$c-bg-success: #e2fce2;
$c-bg-error: #fce9e2;
$c-lemon: #f8e71c;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev-rebecca: rebeccapurple; /* stylelint-disable-line color-named */

// Project colors
$c-default: $c-black;
//$c-yellow: #F7A600; Hexa ok 2022
$c-yellow: rgb(247, 166, 0);
$c-orange-lighter: #fffbfa;
$c-orange-light: #ff6c00;
$c-orange: #E84E0F;
$c-pink-light: #fff2f2;
$c-blue: #009eff;
$c-red: #f00;
$c-dark-green: #00634C;
$c-green: #5DAE99;
$c-light-green: #F5F8F7;

$c-main-primary: $c-orange;
$c-main-secondary: $c-yellow;
$c-main-tertiary: #f9e398; // old light yellow
$c-main-quaternary: #fffbe7; // old very light yellow
$c-main-border: $c-gray-darker;
$c-header-border: $c-gray-lighter;
$c-footer-bg: #575756;
$c-legal-bg: $c-white;
$c-press-bg: #f8f8f8;

/*
* Dimensions
*/
$content-width: 1680px;
$header-height: 60px;
$header-height-commune: 70px;
$header-height-big: 100px;
$header-breakpoint: xl;
$header-commune-breakpoint: m;

/*
* Typography
*/

$base-font-size: 10px !default;

// $ff-default: 'Swiss721BT', sans-serif;
// $ff-alt: 'Martel', sans-serif;

$ff-default: 'proxima-nova', sans-serif;
$ff-alt: 'all-round-gothic', sans-serif; // title

$body-text: 1.5rem;
$body-text-m: 1.8rem;

$font-sizes: (
        h1: 3.6rem,
        h2: 2.8rem,
        h3: 2.4rem,
        h4: 1.4rem,
);

/*
* Base
*/

$border-radius: 20px;
$box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.15);

/**
* RWD
*/
$mq-responsive: true;
$mq-static-breakpoint: 'large';
