@mixin center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin get-all-space {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@mixin image-fit($fit: cover, $position: null) {
  @include get-all-space;

  object-fit: $fit;

  @if $position {
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    object-position: $position;
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

@mixin owl($margin: false) {
  > :first-child {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }
}

@mixin fluid-type($from, $to, $until: xxl) {
  font-size: calc(
          #{$from}px + (#{$to} - #{$from}) *
          ((100vw - #{mq-get-breakpoint-width('xxs')}) /
          (#{strip-unit(mq-get-breakpoint-width($until))} - #{strip-unit(
                mq-get-breakpoint-width(xxs)
              )}))
  );

  @include mq(xxl) {
    font-size: #{$to}px;
  }
}

@mixin fluid($property, $map) {
  $finalmap: ();

  @each $breakpoint, $value in $map {
    $nameToPx: map-get($mq-breakpoints, $breakpoint);

    @if $nameToPx {
      $finalmap: map-merge(
                      $finalmap,
                      (
                              $nameToPx: $value,
                      )
      );
    } @else {
      $finalmap: map-merge(
                      $finalmap,
                      (
                              $breakpoint: $value,
                      )
      );
    }
  }

  @include poly-fluid-sizing($property, $finalmap);
}

@mixin gradient-lines {
  &::before {
    content: '';
    display: block;
    height: 13.5rem;
    background: linear-gradient(
                    to top,
                    $c-yellow 50%,
                    $c-main-tertiary 50%,
                    $c-main-tertiary 80%,
                    $c-main-quaternary 80%,
                    $c-main-quaternary 100%
    );
  }
}

@mixin loading($color: $c-orange) {
  background: linear-gradient(
                  to right,
                  $color 0%,
                  $color 50%,
                  lighten($color, 10) 50%,
                  lighten($color, 10) 70%,
                  $color 70%,
                  $color 100%
  );
  background-size: 200%;
  background-position-x: 200%;
  border: 0;

  &:hover {
    cursor: pointer;
  }

  &.is-loading {
    pointer-events: none;
    color: rgba($c-white, 0.5);
    opacity: 0.8;
    animation: loading-mixin 0.8s linear infinite;
  }
}

@keyframes loading-mixin {
  to {
    background-position-x: 0%;
  }
}

@mixin addPrefix($prefix) {
  position: relative;

  input,
  ::v-deep input {
    padding-left: 3rem;
  }

  &::before {
    content: '#{$prefix}';
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    padding: 0.4rem 0.5rem;
    color: $c-gray;
    line-height: 1;
    background: $c-gray-lightest;
    border-radius: 3px;
  }
}

/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal height and vertical width
/// @param {Color} $foreground-color - color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars(
  $size: 6px,
  $foreground-color: $c-black,
  $background-color: $c-white
) {
  // sass-lint:disable-block no-vendor-prefixes
  // For Google Chrome
  &::-webkit-scrollbar {
    position: absolute;
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

// temporaire
@mixin wrapper-mix {
  width: 100%;
  max-width: $content-width;
  padding-right: col(2, 24);
  padding-left: col(2, 24);

  @include mq(m) {
    padding-right: col(1, 24);
    padding-left: col(1, 24);
  }

  @include mq(xl) {
    padding-right: col(2, 24);
    padding-left: col(2, 24);
  }

  @include mq(wrapper) {
    margin-right: auto;
    margin-left: auto;
    padding-right: calc(#{$content-width} / 24 * 2);
    padding-left: calc(#{$content-width} / 24 * 2);
  }
}

// Custom checkbox

@mixin custom-checkbox($bg-color: $c-main-primary, $check-color: $c-white, $border-width: 0.19rem) {
  position: relative;
  label {
    display: block;
    position: relative;
    padding-left: 2.8rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: $c-white;
    border: 1px solid $c-gray-light;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    border: 1px solid $c-gray;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: $bg-color;
    border: 1px solid $bg-color;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid $check-color;
    border-width: 0 $border-width $border-width 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
